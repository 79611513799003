const DIRECTIONS = {
    Up: 'Up',
    Down: 'Down',
    Left: 'Left',
    Right: 'Right',
};

const { Up, Down, Left, Right } = DIRECTIONS;

const stratagemPatterns = [
    {
      pattern: [Down, Up, Right, Right, Left],
      name: 'Rocket Sentry',
      image: '/stratagem_icons/rocket-sentry.png'
    },
    {
      pattern: [Right, Right, Down, Up],
      name: 'Orbital Smoke Strike',
      image: '/stratagem_icons/orbital-smoke-strike.png'
    },

    {
      pattern: [Down, Up, Right, Up, Left, Up],
      name: 'Autocannon Sentry',
      image: '/stratagem_icons/autocannon-sentry.png'
    },
    {
      pattern: [Down, Left, Down, Up, Up, Down],
      name: 'Supply Pack',
      image: '/stratagem_icons/supply-pack.png'
    },
    {
      pattern: [Down, Up, Right, Up, Left, Right],
      name: 'Tesla Tower',
      image: '/stratagem_icons/tesla-tower.png'
    },
    {
      pattern: [Down, Up, Left, Right, Right, Left],
      name: 'HMG Placement',
      image: '/stratagem_icons/hmg-placement.png'
    },
    {
      pattern: [Right, Right, Left, Down],
      name: 'Orbital EMS Strike',
      image: '/stratagem_icons/orbital-ems-strike.png'
    },
    {
      pattern: [Right, Right, Down, Right],
      name: 'Orbital Gas Strike',
      image: '/stratagem_icons/orbital-gas-strike.png'
    },
    {
      pattern: [Up, Down, Up, Left],
      name: 'Eagle 110MM Rocket Pods',
      image: '/stratagem_icons/eagle-110mm-rocket-pods.png'
    },
    {
      pattern: [Up, Right, Up, Down],
      name: 'Eagle Smoke Strike',
      image: '/stratagem_icons/eagle-smoke-strike.png'
    },
    {
      pattern: [Right, Up, Down, Down, Right],
      name: 'Orbital Railcannon Strike',
      image: '/stratagem_icons/orbital-railcannon-strike.png'
    },
    {
      pattern: [Right, Down, Up, Right, Down],
      name: 'Orbital Laser',
      image: '/stratagem_icons/orbital-laser.png'
    },
    {
      pattern: [Right, Right, Down, Left, Right, Down],
      name: 'Orbital Walking Barrage',
      image: '/stratagem_icons/orbital-walking-barrage.png'
    },
    {
      pattern: [Right, Right, Right],
      name: 'Orbital Airburst Strike',
      image: '/stratagem_icons/orbital-airburst-strike.png'
    },
    {
      pattern: [Down, Down, Up, Down, Down],
      name: 'Spear',
      image: '/stratagem_icons/spear.png'
    },
    {
      pattern: [Down, Left, Up, Down, Up],
      name: 'Flamethrower',
      image: '/stratagem_icons/flamethrower.png'
    },
    {
      pattern: [Up, Right, Down, Down, Down],
      name: 'Eagle 500KG Bomb',
      image: '/stratagem_icons/eagle-500kg-bomb.png'
    },
    {
      pattern: [Down, Up, Left, Down, Right, Right],
      name: 'Shield Generator Relay',
      image: '/stratagem_icons/shield-generator.png'
    },
    {
      pattern: [Down, Down, Up, Right],
      name: 'Resupply',
      image: '/stratagem_icons/resupply.png'
    },
    {
      pattern: [Up, Down, Right, Left, Up],
      name: 'Reinforce',
      image: '/stratagem_icons/reinforce.png'
    },
    {
      pattern: [Down, Left, Down, Up, Right],
      name: 'Machine Gun',
      image: '/stratagem_icons/machine-gun.png'
    },
    {
      pattern: [Down, Left, Right, Up, Down],
      name: 'Anti-Material Rifle',
      image: '/stratagem_icons/anti-material-rifle.png'
    },
    {
      pattern: [Down, Left, Down, Up, Up, Left],
      name: 'Stalwart',
      image: '/stratagem_icons/stalwart.png'
    },
    {
      pattern: [Down, Down, Left, Up, Right],
      name: 'Expendable Anti-Tank',
      image: '/stratagem_icons/expendable-anti-tank.png'
    },
    {
      pattern: [Down, Left, Right, Right, Left],
      name: 'Recoilless Rifle',
      image: '/stratagem_icons/recoilless-rifle.png'
    },
    {
      pattern: [Down, Left, Down, Up, Up, Right],
      name: 'Autocannon',
      image: '/stratagem_icons/autocannon.png'
    },
    {
      pattern: [Down, Right, Left, Down, Up, Left, Right],
      name: 'Railgun',
      image: '/stratagem_icons/railgun.png'
    },    
    {
      pattern: [Right, Down, Left, Up, Up],
      name: 'Orbital Gatling Barrage',
      image: '/stratagem_icons/orbital-gatling-barrage.png'
    },
    {
      pattern: [Right, Down, Left, Right, Down],
      name: 'Orbital 120MM HE Barrage',
      image: '/stratagem_icons/orbital-120mm-he-barrage.png'
    },
    {
      pattern: [Right, Down, Up, Up, Left, Down, Down],
      name: 'Orbital 380MM HE Barrage',
      image: '/stratagem_icons/orbital-380mm-he-barrage.png'
    },
    {
      pattern: [Up, Right, Right],
      name: 'Eagle Strafing Run',
      image: '/stratagem_icons/eagle-strafing-run.png'
    },
    {
      pattern: [Up, Right, Down, Right],
      name: 'Eagle Airstrike',
      image: '/stratagem_icons/eagle-airstrike.png'
    },
    {
      pattern: [Up, Right, Down, Down, Right],
      name: 'Eagle Cluster Bomb',
      image: '/stratagem_icons/eagle-cluster-bomb.png'
    },
    {
      pattern: [Up, Down, Left, Up],
      name: 'SOS Beacon',
      image: '/stratagem_icons/sos.png'
    },
    {
      pattern: [Up, Right, Down, Up],
      name: 'Eagle Napalm Airstrike',
      image: '/stratagem_icons/eagle-napalm-strike.png'
    },
    {
      pattern: [Down, Up, Up, Down, Up],
      name: 'Jump Pack',
      image: '/stratagem_icons/jump-pack.png'
    },
    {
      pattern: [Right, Right, Up],
      name: 'Orbital Precision Strike',
      image: '/stratagem_icons/orbital-precision-strike.png'
    },
    {
      pattern: [Down, Left, Up, Right],
      name: 'Anti-Personnel Minefield',
      image: '/stratagem_icons/anti-personnel-minefield.png'
    },
    {
      pattern: [Down, Left, Up, Left, Down],
      name: 'Grenade Launcher',
      image: '/stratagem_icons/grenade-launcher.png'
    },
    {
      pattern: [Down, Left, Down, Up, Left],
      name: 'Laser Canon',
      image: '/stratagem_icons/laser-cannon.png'
    },
    {
      pattern: [Down, Left, Left, Down],
      name: 'Incendiary Mines',
      image: '/stratagem_icons/incendiary-minefield.png'
    },
    {
      pattern: [Down, Up, Left, Up, Right, Right],
      name: 'Guard Dog Rover',
      image: '/stratagem_icons/guard-dog-rover.png'
    },
    {
      pattern: [Down, Left, Up, Up, Right],
      name: 'Ballistic Shield',
      image: '/stratagem_icons/ballistic-shield.png'
    },
    {
      pattern: [Down, Right, Up, Left, Down],
      name: 'Arc Thrower',
      image: '/stratagem_icons/arc-thrower.png'
    },
    {
      pattern: [Down, Up, Left, Right, Left, Right],
      name: 'Shield Generator Pack',
      image: '/stratagem_icons/shield-generator-pack.png'
    },
    {
      pattern: [Down, Up, Right, Right, Up],
      name: 'Machine Gun Sentry',
      image: '/stratagem_icons/machine-gun-sentry.png'
    },
    {
      pattern: [Down, Up, Right, Left],
      name: 'Gatling Sentry',
      image: '/stratagem_icons/gatling-sentry.png'
    },
    {
      pattern: [Down, Up, Right, Right, Down],
      name: 'Mortar Sentry',
      image: '/stratagem_icons/mortar-sentry.png'
    },
    {
      pattern: [Down, Up, Left, Up, Right, Down],
      name: 'Guard Dog',
      image: '/stratagem_icons/guard-dog.png'
    },
    {
      pattern: [Down, Down, Up, Up, Left],
      name: 'EMS Mortar Sentry',
      image: '/stratagem_icons/ems-mortar-sentry.png'
    }
  ];

  export default stratagemPatterns.sort((a, b) => a.name.localeCompare(b.name));