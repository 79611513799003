import React, { useState, useEffect } from 'react';
import { Button, Modal, Card, CardContent, Typography, Box, Container, Select, MenuItem, FormControl, InputLabel, FormGroup, FormControlLabel, Checkbox, Divider } from '@mui/material';
import { ArrowCircleUp as UpIcon, ArrowCircleDown as DownIcon, ArrowCircleRightOutlined as RightIcon, ArrowCircleLeftOutlined as LeftIcon } from '@mui/icons-material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import stratagemPatterns from './stratagemPatterns';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const KeyPressComponent = () => {
  const [pattern, setPattern] = useState({});
  const [currentIndex, setCurrentIndex] = useState(0);
  const [successMessage, setSuccessMessage] = useState('');
  const [countdown, setCountdown] = useState(0);
  const [selectedStratagem, setSelectedStratagem] = useState('Random Stratagems');
  const [showPattern, setShowPattern] = useState(true);
  const [startTime, setStartTime] = useState(0);
  const [attempts, setAttempts] = useState([]);
  const [showErrorFlash, setShowErrorFlash] = useState(false);
  const [shouldShowFlashError, setShouldShowFlashError] = useState(true);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


  useEffect(() => {
    if (!selectedStratagem || selectedStratagem === 'Random Stratagems') {
      generatePattern();
    }
  }, [selectedStratagem]);

  useEffect(() => {
    if (showErrorFlash) {
      setTimeout(() => {
        setShowErrorFlash(false);
      }, 100);
    }
  }, [showErrorFlash]);

  useEffect(() => {
    let countdownTimer;

    if (countdown > 0) {
      countdownTimer = setTimeout(() => {
        setCountdown(countdown - 1);
      }, 2000);
    } else if (countdown === 0 && successMessage) {
      generatePattern(selectedStratagem);
      setSuccessMessage('');
    }
    return () => clearTimeout(countdownTimer);
  }, [countdown, successMessage, selectedStratagem]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (!startTime) {
        setStartTime(Date.now());
      }

      const direction = mapKeyToDirection(e.key);
      if (['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(e.key)) {
        // Prevent arrow keys from causing window scrolling
        e.preventDefault();
      }

      if (direction) {
        if (pattern.pattern && pattern.pattern[currentIndex] === direction) {
          if (currentIndex === pattern.pattern.length - 1) {
            const endTime = Date.now();
            const duration = (endTime - startTime) / 1000;
            setAttempts([...attempts, { name: pattern.name, duration, success: true }]);
            setSuccessMessage(`Successful Pattern: ${pattern.name} in ${duration.toFixed(2)} seconds`);
            setCurrentIndex(0);
            setStartTime(null);
          } else {
            setCurrentIndex(currentIndex + 1);
          }
        } else {
            setAttempts([...attempts, { name: pattern.name, duration: 0, success: false }]);
            setShowErrorFlash(true);
            setSuccessMessage('');
            setCurrentIndex(0);
            setCountdown(0);
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };

  }, [currentIndex, pattern, startTime, attempts]);

  const mapKeyToDirection = (key) => {
    switch (key) {
      case 'ArrowUp':
      case 'W':
      case 'w':
        return 'Up';
      case 'ArrowDown':
      case 'S':
      case 's':
        return 'Down';
      case 'ArrowLeft':
      case 'A':
      case 'a':
        return 'Left';
      case 'ArrowRight':
      case 'D':
      case 'd':
        return 'Right';
      default:
        return '';
    }
  };

  const getIcon = (direction) => {
    switch (direction) {
      case 'Up':
        return <UpIcon sx={{ fontSize: '3rem' }} />;
      case 'Down':
        return <DownIcon sx={{ fontSize: '3rem' }} />;
      case 'Left':
        return <LeftIcon sx={{ fontSize: '3rem' }} />;
      case 'Right':
        return <RightIcon sx={{ fontSize: '3rem' }} />;
      default:
        return '';
    }
  };

  const generatePattern = (selection = 'Random Stratagems') => {
    if (selection === 'Random Stratagems') {
      const randomPatternIndex = Math.floor(Math.random() * stratagemPatterns.length);
      setPattern(stratagemPatterns[randomPatternIndex]);
    } else {
      const selectedPattern = stratagemPatterns.find(pattern => pattern.name === selection);
      setPattern(selectedPattern);
    }
    setCurrentIndex(0);
    setSuccessMessage('');
    setCountdown(0);
    setStartTime(null);
  };

  const handleSelectionChange = (event) => {
    setSelectedStratagem(event.target.value);
    generatePattern(event.target.value);
    setAttempts([]);
  };

  const handleShowPatternChange = (event) => {
    setShowPattern(event.target.checked);
  };

  const handleShouldShowFlashError = (event) => {
    setShouldShowFlashError(event.target.checked);
  };

  const removeZeroDurationAttempts = (attempts) => {
    return attempts.filter(attempt => attempt.duration > 0);
  };

  const filteredAttempts = removeZeroDurationAttempts(attempts);

  const averageTime = filteredAttempts.length > 0 ? (filteredAttempts.reduce((acc, curr) => acc + curr.duration, 0) / filteredAttempts.length).toFixed(2) : 0;

  const displayRandomAttempts = selectedStratagem === 'Random Stratagems' ? 
    Object.values(attempts.reduce((acc, curr) => {
      const patternName = curr.name;
      if (!acc[patternName]) {
        acc[patternName] = { name: patternName, totalDuration: 0, successCount: 0, attemptCount: 0 };
      }
      if (curr.success) {
        acc[patternName].totalDuration += curr.duration;
        acc[patternName].successCount += 1;
      }
      acc[patternName].attemptCount += 1;
      
      return acc;
    }, {}))
    .map(({ name, totalDuration, successCount, attemptCount }) => {
      const averageDuration = (successCount > 0 && totalDuration > 0) ? (totalDuration / successCount).toFixed(2) : "N/A";
      const incorrectCount = attemptCount - successCount;
      const successRate = attemptCount > 0 ? ((successCount / attemptCount) * 100).toFixed(0) : "0";
      return {
        name,
        text: `${name} (${successCount} Successful, ${incorrectCount} Unsuccessful, ${successRate}% Success Rate) Average Time: ${averageDuration} seconds`
      };
    }).reverse() : [...attempts];

    const displaySpecificAttempts = selectedStratagem !== 'Random Stratagems' ?
      attempts.filter(attempt => attempt.name === selectedStratagem && attempt.success).map(attempt => ({
        name: attempt.name,
        duration: `${attempt.duration.toFixed(2)} seconds`
      })).reverse() : [...attempts];

  return (
    <>
      <Box sx={{
        backgroundImage: 'url(/hd2-bg_dark.jpg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        width: '100vw',
        minHeight: '100vh',
        alignItems: 'center',
        justifyContent: 'center',
        display: {
          xs: 'none',
          sm: 'none',
          md: 'flex',
          lg: 'flex',
          xl: 'flex',
        }
      }}>
        <Container maxWidth="md">
          <Card elevation={0} sx={{ width: '100%', textAlign: 'center', backgroundColor: 'rgba(255, 255, 255)', minHeight: '50vh' }}>
            <CardContent>
              <img src="/stratagem_trainer_logo.png" alt="Helldivers 2 Logo" width={'450px'} height={'auto'} />
              <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', mb: 2, pr: 2, pl: 2 }}>
                <Typography sx={{ mt: 2, fontWeight: 'bold' }} variant="h5" gutterBottom>
                  WELCOME, HELLDIVER!
                </Typography>
                <Typography sx={{ mt: 2 }} variant="h6" gutterBottom>
                  This site was created to help you practice stratagem codes for Helldivers because you can't manage democracy if you can't call in stratagems!
                </Typography>
              </Box>

              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                  <Button variant="outlined" onClick={handleOpen}>Read About Feature Plans</Button>
                </Box>

              <Box sx={{ 
                display: 'flex', 
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                flexDirection: 'column',
                mb: 2,
                pr: 2, 
                pl: 2,
                textAlign: 'left',
              }}>
                <Typography sx={{ mt: 2, fontWeight: 'bold' }} variant="h5" gutterBottom>
                  GUIDE:
                </Typography>

                <List sx={{ width: '100%', listStyleType: 'disc', color: 'black', marginLeft: '20px' }}>
                  <ListItem sx={{ display: 'list-item' }} disablePadding>
                    <ListItemText 
                      primary="Use the arrow keys or W, A, S, D to begin the code. 
                      Do not use 'ctrl' like you would in-game because browsers like Chrome will close the tab. 
                      (Working on controller support)." 
                    />
                  </ListItem>
                  <ListItem sx={{ display: 'list-item' }} disablePadding>
                    <ListItemText 
                      primary="Making a mistake on a stratagem will flash the screen red (if enabled) and restart that code." 
                    />
                  </ListItem>
                  <ListItem sx={{ display: 'list-item' }} disablePadding>
                    <ListItemText 
                      primary="Choosing a specific stratagem will repeat that code displaying individual times for each attempt." 
                    />
                  </ListItem>
                  <ListItem sx={{ display: 'list-item' }} disablePadding>
                    <ListItemText 
                      primary="The default selection 'Random Stratagems' will display a summary of all attempts for all stratagems." 
                    />
                  </ListItem>
                </List>
              </Box>

              <Divider />
              <Card elevation={0} sx={{ backgroundColor: '#D9D9D9', p: 2, pl: 6, pr: 6 }}>
                <FormControl fullWidth sx={{ my: 2 }}>
                  <InputLabel sx={{ fontWeight: 'bold' }} id="stratagem-select-label">Make a Selection</InputLabel>
                  <Select
                    sx={{ fontWeight: 'bold', color: '#444444', backgroundColor: '#d1cfbc', textAlign: 'left' }}
                    labelId="stratagem-select-label"
                    id="stratagem-select"
                    value={selectedStratagem}
                    label="Make a Selection"
                    onChange={handleSelectionChange}
                    autoWidth={true}
                  >
                    <MenuItem value="Random Stratagems">Random Stratagems</MenuItem>
                    {stratagemPatterns.map((pattern) => (
                      <MenuItem key={pattern.name} value={pattern.name}>{pattern.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormGroup sx={{ display: 'flex', flexDirection: 'row', }}>
                  <FormControlLabel control={<Checkbox checked={showPattern} onChange={handleShowPatternChange} />} label="Show Code" />
                  <FormControlLabel control={<Checkbox checked={shouldShowFlashError} onChange={handleShouldShowFlashError} />} label="Flash Red On Mistake" />
                </FormGroup>

                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 6, mb: 2 }}>
                  <Typography variant="body">
                    Start inputting the code to begin:
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                  <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2, p: 6, pb: 0, backgroundColor: showErrorFlash && shouldShowFlashError ? 'red' : '#d1d1d1' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', mb: 2 }}>
                      <Box sx={{ mb: 2 }}>
                        <img src={pattern.image} alt={pattern.name} width={100} height={100} />
                      </Box>
                      
                      <Box sx={{ ml: 2 }}>
                        <Box sx={{ display: 'flex', alignItems: 'left', justifyContent: 'left', mb: 2 }}>
                          <Typography variant="h5">
                            {pattern.name}
                          </Typography>
                        </Box>
                        {showPattern && pattern.pattern && pattern.pattern.map((step, index) => (
                          <Typography
                            key={index}
                            component="span"
                            sx={{
                              color: index < currentIndex ? 'green' : '#6e6b6b',
                              marginRight: 1,
                              fontWeight: index < currentIndex ? 'bold' : 100,
                              textAlign: 'left'
                            }}
                          >
                            {getIcon(step)}
                          </Typography>
                        ))}
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                  <Typography variant='h5' sx={{ fontWeight: 'bold' }}>Total Average Time: {averageTime} seconds</Typography>
                </Box>

                <Box sx={{ maxHeight: '350px', backgroundColor: '#E3E3E3', overflow: 'auto', mt: 2 }}>
                  <Box sx={{ maxHeight: '250px', overflow: 'auto', mt: 2, pb: 1, pl: 2, textAlign: 'left' }}>
                    {selectedStratagem === 'Random Stratagems' ? displayRandomAttempts.map((attempt, index) => (
                      <>
                        <Typography sx={{ fontSize: '0.75rem' }} key={index}>{attempt.text}</Typography>
                        <Divider sx={{ mt: 1, mb: 1 }} />
                      </>
                    )) : displaySpecificAttempts.map((attempt, index) => (
                      <Box sx={{ textAlign: 'center' }}>
                        <Typography sx={{ fontSize: '0.75rem' }} key={index}>{attempt.name} - {attempt.duration}</Typography>
                        <Divider />
                      </Box>
                    ))}
                  </Box>
                </Box>
              </Card>
            </CardContent>
          </Card>

          <Box sx={{ 
            width: '100%', 
            maxHeight: '150px',
            mt: 12,
            pb: 2, 
            display: 'flex', 
            flexDirection: 'column',
            textAlign: 'center', 
            alignItems: 'center', 
            justifyContent: 'center',
            backgroundColor: 'transparent'
          }}>
            <Box>
              <Box sx={{ mt: 2, width: '100%'}}>
                <Typography variant="body2" color="#999999">
                  If you find a bug, incorrect codes, missing codes, want to make a suggestion, 
                  or if you'd just like to scream at me then please send me a DM on Reddit 
                  at <a href="https://www.reddit.com/message/compose?to=hate_it_cuz_i_ate_it&subject=&message=" style={{ textDecoration: 'none', color: 'teal' }} target="_blank" rel="noreferrer">u/hate_it_cuz_i_ate_it</a> or
                  send me an email at <a href="mailto:liberty@stratagemtrainer.com" style={{ textDecoration: 'none', color: 'teal' }}>liberty@stratagemtrainer.com</a> and 
                  I will get back to you just as soon as I can call in a radio tower stratagem.
                </Typography>
                {/* <Box sx={{ mt: 4 }}>
                  <Typography variant="body2" color="#999999">
                    Note: The features evolved in a way that I didn't originally plan and resulted in a bit of a spaghetti code mess. 
                    Once I clean it up I'll open up the repo and link it here in case anyone wants to make a PR or fork it. For now, it's far too embarassing.
                  </Typography>
                </Box> */}
                <Box sx={{ mt: 4 }}>
                  <Typography variant="body2" color="#999999">
                    This site uses no cookies. No tracking. No ads. No bullshit. Just stratagems and beer. 
                    The closest thing is whatever my host uses to tell me how many visitors the page gets because I am curious. 
                    So, I guess, a sprinkle of bullshit? This is a fan project with no Affiliation with Arrowhead Game Studios or Sony Entertainment.
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ mt: 2 }}>
                <Typography variant="body2" color="#999999">
                  If you love the site as much as I love Democracy and Liberty and want to say thanks then feel free to buy me 
                  a <Typography variant="span" sx={{ textDecoration: 'line-through' }}>coffee</Typography> <Typography variant="span" sx={{ fontWeight: 'bold' }}>BEER!</Typography>
                </Typography>
                <Box sx={{ mt: 2 }}>
                  <a href="https://www.buymeacoffee.com/stratagemtrainer" target="_blank" rel="noreferrer"><img src="https://img.buymeacoffee.com/button-api/?text=Buy me a beer&emoji=🍺&slug=stratagemtrainer&button_colour=068ba2&font_colour=ffffff&font_family=Lato&outline_colour=ffffff&coffee_colour=FFDD00" alt="Buy this HELLDIVER a beer!" /></a>
                </Box>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>

      <Box sx={{
        backgroundImage: 'url(/hd2-bg_dark.jpg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        width: '100vw',
        height: '100vh',
        alignItems: 'center',
        display: {
          xs: 'flex',
          sm: 'flex',
          md: 'none',
          lg: 'none',
          xl: 'none',
        },
        p: 0
      }}>
        <Container maxWidth="lg" sx={{ p: 0 }}>
          <Card elevation={0} sx={{ display: 'flex', borderRadius: 0, alignItems: 'center', width: '100%', textAlign: 'left', backgroundColor: 'rgba(255, 255, 255)', minHeight: '20vh' }}>
            <CardContent>
              <Typography variant="h5" sx={{ fontWeight: 'bold' }} gutterBottom>WELCOME, HELLDIVER!</Typography>
              <Typography variant="body2" gutterBottom>
                This site was created to help you practice stratagem codes for Helldivers 2. 
                Unfortunately, you cannot manage Democracy from a phone or tablet and must be on a computer with a physical keyboard and a larger screen.
                Please come back on your computer to practice your stratagems. If you are on your computer and still seeing this message then be sure to increase the size of your browser window.
              </Typography>
              <Typography variant="body2" gutterBottom>
                If you believe this is an error then please contact me at <a href="mailto:liberty@stratagemtrainer.com">liberty@stratagemtrainer.com</a>
              </Typography>
            </CardContent>
          </Card>
        </Container>
      </Box>

      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Feature Plans
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              There are a lot of fun things that can be added to this site to make things a little more interesting. Here is a list of things I am considering but would love to hear some feedback or suggestions from the community!
            </Typography>

            <List sx={{ width: '100%', listStyleType: 'disc', color: 'black', marginLeft: '20px' }}>

              <ListItem sx={{ display: 'list-item', mt: 2 }} disablePadding>
                <Typography variant="body2">
                  <Typography variant="span" sx={{ fontWeight: 'bold' }}>Custom Loadout - </Typography> 
                  Currently working on adding custom loadouts to practice so you can practice the stratagems you use the most.
                </Typography>
              </ListItem>

              <ListItem sx={{ display: 'list-item', mt: 2 }} disablePadding>
                <Typography variant="body2">
                  <Typography variant="span" sx={{ fontWeight: 'bold' }}>Global Leaderboard -</Typography> My biggest concern with this is cheaters. If we get people cheating in a PvE game (and possibly already in Stratagem Hero) then imagine the cheaters here with a leaderboard.
                </Typography>
              </ListItem>

              <ListItem sx={{ display: 'list-item', mt: 2 }} disablePadding>
                <Typography variant="body2">
                  <Typography variant="span" sx={{ fontWeight: 'bold' }}>Preserved Stats -</Typography> Returning to the site would remember your previous session and stats. This would require cookies so I would offer an opt-out option.
                </Typography>
              </ListItem>

              <ListItem sx={{ display: 'list-item', mt: 2 }} disablePadding>
                <Typography variant="body2">
                  <Typography variant="span" sx={{ fontWeight: 'bold' }}>Shareable Links -</Typography> Would love to create a shareable link to your stats so you can show off your times to your friends.
                </Typography>
              </ListItem>

              <ListItem sx={{ display: 'list-item', mt: 2 }} disablePadding>
                <Typography variant="body2">
                  <Typography variant="span" sx={{ fontWeight: 'bold' }}>Controller Support -</Typography> I think I know how to do this but need to play with it. I'll need to borrow a PS5 controller to test it.
                </Typography>
              </ListItem>

              <ListItem sx={{ display: 'list-item', mt: 2 }} disablePadding>
                <Typography variant="body2">
                  <Typography variant="span" sx={{ fontWeight: 'bold' }}>Daily Challenges -</Typography> I love Wordle and think a unique daily stratagem challenge with results that you could copy and paste would be fun.
                </Typography>
              </ListItem>

              <ListItem sx={{ display: 'list-item', mt: 2 }} disablePadding>
                <Typography variant="body2">
                  <Typography variant="span" sx={{ fontWeight: 'bold' }}>Fixing Things -</Typography> Not a feature but it seems like for every bug or display issue I fix I find another one so I will keep adding fixes as I can.
                </Typography>
              </ListItem>
            </List>

            <Button sx={{ width: '100%', mt: 6, backgroundColor: '#000', '&:hover': { backgroundColor: '#526e75' } }} variant="contained" onClick={handleClose}>Close</Button>

          </Box>
        </Modal>
      </div>
    </>
  );
};

export default KeyPressComponent;
